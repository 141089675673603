<template>
    <div class="user-item-wrap">
        <div class="user-item-inner">
            <div class="user-header">
                <div class="title">Traingo隐私政策</div>
                <div class="date">2021年10月31日</div>
            </div>
            <div class="user-item-content">
                <p>昶戈微学（以下简称“本应用”）是一款由上海昶戈信息科技有限公司（以下简称“我们”）研发和运营的在线培训产品。你的隐私对我们而言至关重要。因此，我们制定了本隐私政策，其中说明了我们如何收集、使用、披露、转让和存储你的信息。请你仔细阅读我们的隐私政策，如有任何问题，请告知我们。</p>
                <p><span class="pt">1.适用范围</span></p>
                <p>a)在您使用本应用网络服务，或访问本应用平台网页时，本应用自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；</p>
                <p><span class="pt">2.信息的使用</span></p>
                <p>a)在获得您的数据之后，本应用会将其上传至服务器，以生成您的排行榜数据，以便您能够更好地使用服务。</p>
                <p>b)我们的产品基于DCloud uni-app(5+ App/Wap2App)开发，应用运行期间需要收集您的设备唯一识别码（IMEI/android ID/DEVICE_ID/IDFA、SIM 卡 IMSI 信息）以提供统计分析服务，并通过应用启动数据及异常错误日志分析改进性能和用户体验，为用户提供更好的服务。详情内容请访问《DCloud用户服务条款》。（DCloud用户服务条款超链至：https://ask.dcloud.net.cn/protocol.html）</p>
                <p><span class="pt">3.信息披露</span></p>
                <p>a)本应用不会将您的信息披露给不受信任的第三方。</p>
                <p>b)根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；</p>
                <p>c)如您出现违反中国有关法律、法规或者相关规则的情况，需要向第三方披露；</p>
                <p><span class="pt">4.信息存储和交换</span></p>
                <p>本应用收集的有关您的信息和资料将保存在本软件及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或本应用收集信息和资料所在地的境外并在境外被访问、存储和展示。</p>
                <p><span class="pt">5.信息安全</span></p>
                <p>a)本应用的帐号均有安全保护功能，请妥善保管您的用户名及密码信息。本应用将通过对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在“完善的安全措施”。 </p>
                <p>b)在使用本应用网络服务时，请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是本应用的用户名及密码发生泄露，请您立即联络客服或管理员，以便采取相应措施。</p>
                <br/>
                <p>以上声明内容最终解释权在法律允许内归本站所有</p>
            </div>
        </div>
    </div>
</template>

<script>
</script>

<style lang="less" scoped>
@import '../../assets/style/agreement.less';
</style>